import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import IndexPage from '../components/IndexPage/IndexPage'
import { useTracking } from '../hooks/useTracking'

const Index: React.FC<PageProps> = (props) => {
  const { init } = useTracking()

  useEffect(() => {
    init()
  }, [])

  return (
    <IndexPage {...props}/>
  )
}

export default Index
