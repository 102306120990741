import React from "react"
import classNames from "classnames"

import componentStyles from "./ProjectCard.module.scss"
import { ProjectItem } from "../../hooks"
import { useIntl } from "gatsby-plugin-intl"

interface ProjectCardProps extends ProjectItem {
  className?: string
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  className,
  theme,
  thumbnail,
  title,
  link,
}) => {
  const intl = useIntl()
  const cardClasses = classNames(
    componentStyles.project,
    className,
    {
      [componentStyles[`project-theme-${theme}`]]: theme,
    },
    theme ? theme : ""
  )
  const thumbnailClasses = classNames(componentStyles.projectThumbnail)
  const linkClasses = classNames(componentStyles.projectLink)

  const getFullPath = (url: string) => {
    if (url) {
      return `/${intl.locale}/projects/${url}/`
    }
    return ""
  }

  link = link ? getFullPath(link) : undefined

  const Thumbnail = !thumbnail ? (
    <picture className={thumbnailClasses} />
  ) : (
    <picture className={thumbnailClasses}>
      <source
        type="image/webp"
        srcSet={thumbnail.childImageSharp.fixed.srcSetWebp}
      />
      <source
        type="image/png"
        srcSet={thumbnail.childImageSharp.fixed.srcSet}
      />
      <img
        className={componentStyles.projectThumbnailImage}
        src={thumbnail.childImageSharp.fixed.src}
        srcSet={thumbnail.childImageSharp.fixed.srcSet}
        width={thumbnail.childImageSharp.fixed.width}
        height={thumbnail.childImageSharp.fixed.height}
        alt={`${(title && intl.formatMessage({ id: title })) || ""} thumbnail`}
      />
    </picture>
  )

  return (
    <article className={cardClasses}>
      <a className={linkClasses} href={link}>
        {Thumbnail}
        <h4 className={componentStyles.projectTitle}>
          {(title && intl.formatMessage({ id: title })) || ""}
        </h4>
      </a>
    </article>
  )
}

export default ProjectCard
