import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import componentStyles from "./AdvantagesBlock.module.scss"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const AdvantagesBlock = () => {
    const intl = useIntl()
    const breakpoints = useBreakpoint();


    return (
        <div className={componentStyles.companyAdvantagesList}>
            <div className={componentStyles.companyAdvantagesItem}>
                <span className={componentStyles.companyAdvantagesItemMainInfo}>
                    {intl.formatMessage({
                    id: "index.company.right-block.first-big",
                    })}
                    <span>
                    {intl.formatMessage({
                        id: "index.company.right-block.first-big-span",
                    })}
                    </span>
                </span>
                <span>
                    {intl.formatMessage({
                    id: "index.company.right-block.first-small",
                    })}
                </span>
            </div>
            <div className={componentStyles.companyAdvantagesItem}>
                <span className={componentStyles.companyAdvantagesItemMainInfo}>
                    {intl.formatMessage({
                    id: "index.company.right-block.second-big",
                    })}
                </span>
                <span>
                    {intl.formatMessage({
                    id: "index.company.right-block.second-small",
                    })}
                </span>
            </div>
            <div className={componentStyles.companyAdvantagesItem}>
                <span className={componentStyles.companyAdvantagesItemMainInfo}>
                    {intl.formatMessage({
                    id: "index.company.right-block.third-big",
                    })}
                </span>
                <span>
                    {intl.formatMessage({
                    id: "index.company.right-block.third-small",
                    })}
                </span>
            </div>
            <div className={componentStyles.companyAdvantagesItem}>
                <span className={componentStyles.companyAdvantagesItemIcon}>
                    <img
                    src="/icons/machine-learning.svg"
                    alt="Certificate icon"
                    width={!breakpoints.mediaBefore800 ? '74' : '40'}
                    height={!breakpoints.mediaBefore800 ? '61' : '32.6'}
                    />
                </span>
                <span>
                    {intl.formatMessage({
                    id: "index.company.right-block.forth-small",
                    })}
                </span>
            </div>
        </div>
    )
  }

  export default AdvantagesBlock