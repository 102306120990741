import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"
import classNames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import Glide from "@glidejs/glide"
import componentStyles from "./IndexPage.module.scss"

import Layout from "../Layout/Layout"
import SEO from "../seo"
import { useBlogPosts, usePartners, projectsList } from "../../hooks"
import ProjectCard from "../ProjectCard/ProjectCard"
import Button from "../Button/Button"
import { Link } from "../Link"
import ArtificialIntelligenceIcon from "../../assets/images/icons/artificial-intelligence.svg"
import MachineLearningIcon from "../../assets/images/icons/machine-learning.svg"
import NaturalLanguageProcessingIcon from "../../assets/images/icons/natural-language-understanding.svg"
import DataScienceIcon from "../../assets/images/icons/data-science.svg"
import ComplexIntegrationsIcon from "../../assets/images/icons/integration.svg"
import RecommendationIcon from "../../assets/images/icons/recommendation.svg"
import AnalyticsReportingIcon from "../../assets/images/icons/analytics-reporting.svg"
import ForecastingIcon from "../../assets/images/icons/forecasting.svg"
import OptimizationIcon from "../../assets/images/icons/optimization.svg"
import HighLoadIcon from "../../assets/images/icons/high-load.svg"
import MobileDevelopmentIcon from "../../assets/images/icons/mobile-development.svg"
import CloudServicesIcon from "../../assets/images/icons/cloud-services.svg"
import {
  getThumbnailByLink,
  useProjectsCardsThumbnails,
} from "../../hooks/useProjectsCardsThumbnails"
import PresentationForm from "../PresentationForm/PresentationForm"
import AdvantagesBlock from "../AdvantagesBlock/AdvantagesBlock"

const specializations = [
  {
    icon: ArtificialIntelligenceIcon,
    name: "artificial-intelligence",
    titleTranslatePath: "index.specialization-block.list.first-item",
  },
  {
    icon: MachineLearningIcon,
    name: "machine-learning",
    titleTranslatePath: "index.specialization-block.list.forth-item",
  },
  {
    icon: NaturalLanguageProcessingIcon,
    name: "natural-language-processing",
    titleTranslatePath: "index.specialization-block.list.seventh-item",
  },
  {
    icon: DataScienceIcon,
    name: "data-science",
    titleTranslatePath: "index.specialization-block.list.tenth-item",
  },
  {
    icon: ComplexIntegrationsIcon,
    name: "complex-integrations",
    titleTranslatePath: "index.specialization-block.list.second-item",
  },
  {
    icon: RecommendationIcon,
    name: "recommendation",
    titleTranslatePath: "index.specialization-block.list.fifth-item",
  },
  {
    icon: AnalyticsReportingIcon,
    name: "analytics-reporting",
    titleTranslatePath: "index.specialization-block.list.eighth-item",
  },
  {
    icon: ForecastingIcon,
    name: "forecasting",
    titleTranslatePath: "index.specialization-block.list.eleventh-item",
  },
  {
    icon: OptimizationIcon,
    name: "optimization",
    titleTranslatePath: "index.specialization-block.list.third-item",
  },
  {
    icon: HighLoadIcon,
    name: "high-load",
    titleTranslatePath: "index.specialization-block.list.sixth-item",
  },
  {
    icon: MobileDevelopmentIcon,
    name: "mobile-development",
    titleTranslatePath: "index.specialization-block.list.ninth-item",
  },
  {
    icon: CloudServicesIcon,
    name: "cloud-services",
    titleTranslatePath: "index.specialization-block.list.twelfth-item",
  },
]

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useProjectsCardsThumbnails()
  const partners = usePartners()
  const blogPosts = useBlogPosts(intl.locale)

  useEffect(() => {
    if (
      componentStyles?.partners &&
      document.querySelector(`.${componentStyles.partners}`)
    ) {
      const glide = new Glide(`.${componentStyles.partners}`, {
        type: "carousel",
        keyboard: false,
        autoplay: 3000,
        hoverpause: false,
        startAt: 0,
        perView: 5,
        gap: 24,
        direction: intl.locale === "ar" ? "rtl" : "ltr",
        breakpoints: {
          800: {
            perView: 3,
          },
          600: {
            perView: 2,
          },
        },
      }).mount()
    }
  }, [partners])

  useEffect(() => {
      const glide = new Glide('.glide', {
        type: 'slider',
        keyboard: false,
        bound: true,
        swipeThreshold: false,
        perView: 7,
        autoplay: false,
        startAt: 0,
        gap: 16,
        direction: intl.locale === "ar" ? "rtl" : "ltr",
        hoverpause: false,
        breakpoints: {
          800: {
            type: 'carousel',
            perView: 3,
            startAt: 0,
            autoplay: 3000,
            bound: false,
            swipeThreshold: true,
          },
          1200: {
            type: 'carousel',
            perView: 4,
            startAt: 0,
            autoplay: 3000,
            bound: false,
            swipeThreshold: true,
          },
          1300: {
            type: 'carousel',
            perView: 5,
            startAt: 0,
            autoplay: 3000,
            bound: false,
            swipeThreshold: true,
          },
        },
      }).mount();
  }, []);

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const specializationClasses = classNames(
    componentStyles.specialization,
    "container"
  )
  const clutchWinnersClasses = classNames(componentStyles.clutchWinners)
  const specializationTitleClasses = classNames(
    componentStyles.specializationTitle,
    "large-section-title"
  )

  const projectsClasses = classNames(componentStyles.projects, "container")
  const projectsListClasses = classNames(componentStyles.projectsList, "grid-2")
  const projectsTitleClasses = classNames(
    componentStyles.projectsTitle,
    "large-section-title"
  )

  const platformsClasses = classNames(
    componentStyles.companyPlatforms,
    "glide"
  )
  const platformsItemClasses = classNames(
    componentStyles.companyPlatformsLink,
    "glide__slide"
  )
  const platformsListClasses = classNames(
    componentStyles.companyPlatformsList,
    "glide__slides"
  )

  const partnersClasses = classNames(
    componentStyles.partners,
    "container",
    "glide"
  )
  const partnersTitleClasses = classNames("large-section-title")
  const partnersWrapperClasses = classNames(
    "glide__track"
  )
  const partnersListClasses = classNames(
    componentStyles.partnersList,
    "glide__slides"
  )
  const partnersItemClasses = classNames(
    "glide__slide"
  )

  const blogClasses = classNames(componentStyles.blog, "container")
  const blogWrapperClasses = classNames(componentStyles.blogWrapper)
  const blogListClasses = classNames(componentStyles.blogList, "grid-3")
  const blogTitleClasses = classNames(
    componentStyles.blogTitle,
    "large-section-title"
  )

  const companyClasses = classNames(componentStyles.company, "container")
  const companyContainerClasses = classNames("grid-2")
  const companyTitleClasses = classNames(
    componentStyles.companyTitle,
    "large-section-title"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "index.seo.description" })}
        title={intl.formatMessage({ id: "index.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerLeftTitle}>
            {intl.formatMessage({ id: "index.banner.title" })}
          </h1>
          <div className={componentStyles.bannerLeftWithImage}>
            <img
              src="/images/main-illustration.svg"
              alt="Illustration with abstract IT infrastructure"
              width="690"
              height="476"
            />
          </div>
          <div>
            <div className={componentStyles.bannerLeftText}>
              {intl.formatMessage({ id: "index.banner.text" })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.bannerAwards}>
          <div className={componentStyles.bannerAwardsText}>
            Inventale Custom Projects is the 2023 award-winning company in United Arabic Emirates for its{' '}
            <a href="https://techbehemoths.com/awards-2023/reactjs/united-arab-emirates?t_category=Embedded%20Awards%20Banner%202023&t_action=banner_click&t_label=http%3A%2F%2Flocalhost%3A8000%2Fen%2F%20-%20Inventale%20Custom%20Projects%20-%20ReactJs%20-%20United%20Arab%20Emirates#view=60828" className={componentStyles.bannerAwardsTextLink} target="_blank">ReactJs</a>,{' '}
            <a href="https://techbehemoths.com/awards-2023/artificial-intelligence/united-arab-emirates?t_category=Embedded%20Awards%20Banner%202023&t_action=banner_click&t_label=http%3A%2F%2Flocalhost%3A8000%2Fen%2F%20-%20Inventale%20Custom%20Projects%20-%20Artificial%20Intelligence%20-%20United%20Arab%20Emirates#view=60828" className={componentStyles.bannerAwardsTextLink} target="_blank">Artificial Intelligence</a> and {' '}
            <a href="https://techbehemoths.com/awards-2023/custom-software-development/united-arab-emirates?t_category=Embedded%20Awards%20Banner%202023&t_action=banner_click&t_label=http%3A%2F%2Flocalhost%3A8000%2Fen%2F%20-%20Inventale%20Custom%20Projects%20-%20Custom%20Software%20Development%20-%20United%20Arab%20Emirates#view=60828" className={componentStyles.bannerAwardsTextLink} target="_blank">Custom Software Development</a> services
          </div>
        </div>
      </section>
      <section className={specializationClasses}>
        <h2 className={specializationTitleClasses}>
          {intl.formatMessage({ id: "index.specialization-block.title" })}
        </h2>
        <div className={componentStyles.specializationListBlock}>
          <ul className={componentStyles.specializationListBlockList}>
            {specializations.map(
              ({ icon, name, titleTranslatePath }, index) => (
                <li
                  key={name}
                  className={componentStyles.specializationListBlockItem}
                >
                  <img
                    src={icon}
                    alt={`${intl.formatMessage({
                      id: titleTranslatePath,
                    })} icon`}
                    width="24"
                    height="24"
                  />
                  <span>
                    {intl.formatMessage({
                      id: titleTranslatePath,
                    })}
                  </span>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
      <section className={projectsClasses}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.title" })}
        </h2>
        <ul className={projectsListClasses}>
          {projects.slice(0, 6).map(({ title, theme, link }) => {
            const thumbnail = getThumbnailByLink(link, projectsThumbnails)
            return (
              <li
                className={componentStyles.projectsItem}
                key={link}
                tabIndex={0}
              >
                <ProjectCard
                  title={title}
                  theme={theme}
                  thumbnail={thumbnail}
                  link={link}
                />
              </li>
            )
          })}
        </ul>
        <Button
          to={"/projects"}
          color={"orange"}
          className={componentStyles.projectsLink}
        >
          {intl.formatMessage({ id: "index.projects.link" })}
        </Button>
      </section>
      <section className={companyClasses}>
        <h2 className={companyTitleClasses}>
          {intl.formatMessage({ id: "index.company.title" })}
        </h2>
        <div className={companyContainerClasses}>
          <div>
            <div className={componentStyles.companyWithImage}>
              <span>
                {intl.formatMessage({ id: "index.company.image-title" })}
              </span>
            </div>
            <div className={componentStyles.companyLeftBlock}>
              <Link
                className={componentStyles.badge}
                to="//upwork.com/"
              >
                <img
                  src="/images/upwork.svg"
                  alt="Upwork logo"
                  width="138"
                  height="42"
                />
              </Link>
              <Link
                className={componentStyles.badge}
                to="//upwork.com/"
              >
                <img
                  src="/images/clutch.svg"
                  alt="Upwork logo"
                  width="138"
                  height="42"
                />
              </Link>
            </div>
          </div>
          <AdvantagesBlock/>
        </div>
      </section>
      <div className={platformsClasses}>
        <div className="glide__track" data-glide-el="track">
          <ul className={platformsListClasses}>
            <li className={platformsItemClasses}>
              <Link to="//techbehemoths.com/awards-2023/artificial-intelligence/united-arab-emirates#view=60828" className={componentStyles.companyPlatformsLink}>
                <img
                  src="/images/winnerAI.svg"
                  alt="Clutch logo"
                  width="83"
                  height="93"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.winner-AI" })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
              className={componentStyles.companyPlatformsLink}
              to="//techbehemoths.com/awards-2023/custom-software-development/united-arab-emirates#view=60828"
              >
                <img
                  src="/images/winnerCSD.svg"
                  alt="Clutch logo"
                  width="83"
                  height="93"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.winner-CSD" })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
                className={componentStyles.companyPlatformsLink}
                to="//clutch.co/profile/inventale"
              >
                <img
                  src="/images/clutch.png"
                  alt="Clutch logo"
                  width="83"
                  height="93"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.clutch-fusten-growth" })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
                className={classNames(
                  componentStyles.companyPlatformsLink,
                  componentStyles.companyAwardsItem
                )}
                to="//clutch.co/press-releases/uae%E2%80%99s-2021-leading-b2b-companies-honored-celebrated"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet='/images/clutch-winners.webp'
                  />
                  <source
                    type="image/png"
                    srcSet='/images/clutch-winners.png'
                  />
                  <img
                    src="/images/clutch-winners.png"
                    alt="Clutch winner badge"
                    width="90"
                    height="98"
                  />
                </picture>
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.clutch-winners" })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
                to="//www.goodfirms.co/company/inventale"
                className={componentStyles.companyAwardsItem}
              >
                <img
                  className={componentStyles.companyAwardsBadge}
                  src="/images/top-software.svg"
                  alt="Goodfirms Top Software Development Company badge"
                  width="133"
                  height="98"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.bottom-block.first" })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
                to="//www.goodfirms.co/company/inventale"
                className={componentStyles.companyAwardsItem}
              >
                <img
                  className={componentStyles.companyAwardsBadge}
                  src="/images/big-data-analytics.svg"
                  alt="Goodfirms Top Big Data Analytics Company badge"
                  width="133"
                  height="98"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({
                    id: "index.company.bottom-block.second",
                  })}
                </span>
              </Link>
            </li>
            <li className={platformsItemClasses}>
              <Link
                to="//www.goodfirms.co/company/inventale"
                className={componentStyles.companyAwardsItem}
              >
                <img
                  className={componentStyles.companyAwardsBadge}
                  src="/images/artificial-intelligence.svg"
                  alt="Goodfirms Top Artificial Intelligence Company badge"
                  width="133"
                  height="98"
                />
                <span className={componentStyles.companyAwardsDescription}>
                  {intl.formatMessage({ id: "index.company.bottom-block.third" })}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <section className={partnersClasses}>
        <h2 className={partnersTitleClasses}>
          {intl.formatMessage({ id: "index.partners.title" })}
        </h2>
        <div className={partnersWrapperClasses} data-glide-el="track">
          <ul className={partnersListClasses}>
            {partners.edges.map(({ node }, index) => (
              <li className={partnersItemClasses} key={`item-${index}`}>
                <picture className={componentStyles.partnersLogo}>
                  <source
                    type="image/webp"
                    srcSet={node.childImageSharp.fixed.srcSetWebp}
                  />
                  <source
                    type="image/png"
                    srcSet={node.childImageSharp.fixed.srcSet}
                  />
                  <img
                    src={node.childImageSharp.fixed.src}
                    srcSet={node.childImageSharp.fixed.srcSet}
                    width={node.childImageSharp.fixed.width}
                    height={node.childImageSharp.fixed.height}
                    alt=""
                  />
                </picture>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
